<template>
  <div class="row">
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">CPF</th>
            <th scope="col">Nome</th>
            <th scope="col">Email</th>
            <th scope="col">Telefone</th>
            <th scope="col">Data</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="val in info" :key="val.reserva_chave_id">
            <td>{{ val.cpf }}</td>
            <td>{{ val.nome }}</td>
            <td>{{ val.email }}</td>
            <td>{{ val.telefone }}</td>
            <td>{{ val.data }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { request } from "../../assets/utils";

export default {
  props: {
    imovel_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      info: null,
    };
  },
  async mounted() {
    const load = this.$loading.show();
    const response = await request(
      this,
      "GET",
      "/relatorio/listaInteresse/" + this.imovel_id,
      this.$session.get("token"),
      {}
    );
    load.hide();
    if (!response.error) {
      this.info = response;
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response.message,
      });
    }
  },
};
</script>

<style>
</style>