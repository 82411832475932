<template>
  <div id="app">
    <template v-if="this.$store.state.logado">
      <Sidebar>
        <router-view></router-view>
      </Sidebar>
    </template>
    <template v-else>
      <Login></Login>
    </template>
  </div>
</template>
<script>
import Sidebar from "./components/layout/Sidebar";
import Login from "./views/Login.vue";
import { request } from "./assets/utils";
import Swal from "sweetalert2";

export default {
  components: {
    Sidebar,
    Login,
  },
  beforeCreate: async function () {
    if (this.$session.exists()) {
      this.$store.state.logado = true;
    } else if (localStorage.getItem("token")) {
      const load = this.$loading.show();
      const token = localStorage.getItem("token");
      const response = await request(this,"POST", "/autologin", token, {});
      load.hide();

      if (response.auth) {
        this.$session.set("token", response.token);
        this.$store.state.logado = true;
      } else {
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: "Faça login novamente",
        });
      }

    }
  },
};
</script>

