<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <label for="cpf" class="form-label">CPF</label>
        <input type="text" class="form-control" id="cpf" v-model="cpf" />
      </div>
      <div class="col-md-6">
        <label for="nome" class="form-label">Nome</label>
        <input type="text" class="form-control" id="nome" v-model="nome" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="email" class="form-label">Email</label>
        <input type="email" class="form-control" id="email" v-model="email" />
      </div>
      <div class="col-md-6">
        <label for="tel" class="form-label">Telefone</label>
        <input type="tel" class="form-control" id="tel" v-model="tel" />
      </div>
    </div>
    <hr />
    <div class="row justify-content-center">
      <div class="col-md-auto">
        <button class="btn btn-success" @click="salvar()">Salvar</button>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      nome: "",
      cpf: "",
      email: "",
      tel: "",
    };
  },
  methods: {
    salvar() {
      if (this.nome && this.cpf && this.email && this.tel) {
        this.$emit("salvar", {
          nome: this.nome,
          cpf: this.cpf,
          email: this.email,
          telefone: this.tel,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Todos os campos são obrigatórios!",
        });
      }
    },
  },
};
</script>

<style>
</style>