<template>
  <div>
    <div id="grafico" style="width: 100%; min-height: 300px"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Swal from "sweetalert2";
import { request } from "../../assets/utils";

export default {
  props: {
    imovel_id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    geraGrafico() {
      const chartDom = document.getElementById("grafico");
      this.grafico = echarts.init(chartDom);

      const labels = [];

      //SEPRARA AS LABELS
      this.info.map((val) => {
        const label = `${val.mes}/${val.ano}`;
        if (labels.indexOf(label) == -1) labels.push(label);
      });

      const totalVisistas = [];
      const visitasSemInterresse = [];
      const visitasInterresse = [];
      const visitasInterresseNegociacao = [];

      for (let i = 0; i < labels.length; i++) {
        const mesAno = labels[i];

        //SEPERA OS DADOS POR MES E ANO
        const infoMesAno = this.info.filter((val) => {
          return `${val.mes}/${val.ano}` == mesAno;
        });

        let totalMesAno = 0;

        let check_visitasSemInterresse = false;
        let check_visitasInterresse = false;
        let check_visitasInterresseNegociacao = false;

        for (let j = 0; j < infoMesAno.length; j++) {
          if (infoMesAno[j].interesse == "semInteresse") {
            visitasSemInterresse.push(infoMesAno[j].numVisitas);
            check_visitasSemInterresse = true;
          } else if (infoMesAno[j].interesse == "interesse") {
            visitasInterresse.push(infoMesAno[j].numVisitas);
            check_visitasInterresse = true;
          } else if (infoMesAno[j].interesse == "interesseNegociacao") {
            visitasInterresseNegociacao.push(infoMesAno[j].numVisitas);
            check_visitasInterresseNegociacao = true;
          }

          totalMesAno += infoMesAno[j].numVisitas;
        }

        if (!check_visitasSemInterresse) {
          visitasSemInterresse.push(0);
        }
        if (!check_visitasInterresse) {
          visitasInterresse.push(0);
        }
        if (!check_visitasInterresseNegociacao) {
          visitasInterresseNegociacao.push(0);
        }

        totalVisistas.push(totalMesAno);
      }

      const option = {
        // Make gradient line here
        visualMap: [
          {
            show: false,
            type: "continuous",
            seriesIndex: 0,
            min: Math.min(...totalVisistas),
            max: Math.max(...totalVisistas),
          },
        ],
        color: ["#ecc994", "#87a980","#5470c6","#000"],
        title: [
          {
            text: "Visitas ao imovel",
          },
        ],
        legend: {
          data: ["Total de visitas","Interesse","Interesse Negociação", "Sem interesse"],
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            data: labels,
          },
        ],
        yAxis: [{}],
        grid: [
          {
            bottom: "60%",
          },
        ],
        series: [
          {
            type: "line",
            showSymbol: false,
            data: totalVisistas,
            name: "Total de visitas",
          },
          {
            type: "line",
            showSymbol: false,
            data: visitasInterresse,
            name: "Interesse",
          },
          {
            type: "line",
            showSymbol: false,
            data: visitasInterresseNegociacao,
            name: "Interesse Negociação",
          },
          {
            type: "line",
            showSymbol: false,
            data: visitasSemInterresse,
            name: "Sem interesse",
          },
        ],
      };

      option && this.grafico.setOption(option);
    },
  },
  data() {
    return {
      grafico: null,
      info: [],
    };
  },
  async mounted() {
    const load = this.$loading.show();
    const response = await request(
      this,
      "GET",
      "/relatorio/imovel/" + this.imovel_id,
      this.$session.get("token"),
      {}
    );
    load.hide();
    if (!response.error) {
      this.info = response;
      this.geraGrafico();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response.message,
      });
    }
  },
};
</script>

<style>
</style>