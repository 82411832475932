import Vue from 'vue'
import VueRouter from 'vue-router'
import Teste from '../views/Teste'
import ListaImovel from '../views/ListaImovel'
import NovaReserva from '../views/NovaReserva'
import ListaReserva from '../views/ListaReserva'
import RelatorioImovel from '../views/RelatorioImovel';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Teste
  },
  {
    path: '/novaReserva',
    component: ListaImovel
  },
  {
    path: '/reserva/:id',
    component: NovaReserva
  },
  {
    path: '/reservas',
    component: ListaReserva
  },
  {
    path: '/relatorio',
    component: RelatorioImovel
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
