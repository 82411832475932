<template>
  <div>
    <VueModal
      v-model="showModal"
      :modalStyle="{ 'max-width': '80%' }"
      title="Devolução"
    >
      <Devolucao :id_reserva="id_reserva_devolucao"></Devolucao>
    </VueModal>
    <div class="row mb-4">
      <div class="col-md-6">
        <label for="filtro" class="form-label">CPF</label>
        <input type="text" class="form-control" id="filtro" v-model="filtro" />
      </div>
      <div class="col-md-6">
        <label for="filtro_status" class="form-label">Status</label>
        <select class="form-select" id="filtro_status" v-model="filtro_status">
          <option value="">Selecione</option>
          <option value="1">Devolvido</option>
          <option value="0">Em empréstimo</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">CPF</th>
              <th scope="col">Nome</th>
              <th scope="col">Email</th>
              <th scope="col">Imóvel</th>
              <th scope="col">Endereço</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <ReservaListaItem
              v-for="reserva in reservaFiltro"
              :key="reserva.reserva_chave_id"
              :reserva="reserva"
              v-on:devolucao="devolucao"
            ></ReservaListaItem>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { request } from "../assets/utils";
import ReservaListaItem from "../components/reserva/ReservaListaItem";
import VueModal from "@kouts/vue-modal";
import Devolucao from "../components/reserva/Devolucao.vue";

export default {
  components: {
    ReservaListaItem,
    VueModal,
    Devolucao,
  },
  data() {
    return {
      reserva: [],
      reservaFiltro: [],
      filtro: "",
      filtro_status: "",
      showModal: false,
      id_reserva_devolucao: null,
    };
  },
  methods: {
    filtragem(value) {
      var re = new RegExp(this.filtro, "i");
      let filtro_status_cond = true;

      if (this.filtro_status != "")
        filtro_status_cond = value.devolucao == this.filtro_status;

      return value.cpf.search(re) !== -1 && filtro_status_cond;
    },
    devolucao(info) {
      this.id_reserva_devolucao = info.reserva_chave_id;
      this.showModal = true;
    },
  },
  watch: {
    filtro() {
      this.reservaFiltro = this.reserva.filter(this.filtragem);
    },
    filtro_status() {
      this.reservaFiltro = this.reserva.filter(this.filtragem);
    },
  },
  async mounted() {
    const load = this.$loading.show();
    const response = await request(
      this,
      "GET",
      "/chave/lista",
      this.$session.get("token"),
      {}
    );
    if (!response.error) {
      this.reserva = response;
      this.reservaFiltro = response;
      load.hide();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response.message,
      });
    }
  },
};
</script>

<style>
</style>