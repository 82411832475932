<template>
  <div class="container">
    <!-- BOTOES -->
    <div class="row" v-if="!intencaoEscolhida">
      <div class="col-md-4">
        <button class="btn btn-success w-100" @click="interesse('interesse')">
          Tem interesse
        </button>
      </div>
      <div class="col-md-4">
        <button
          class="btn btn-warning w-100"
          @click="interesse('interesseNegociacao')"
        >
          Tem interesse em negociar
        </button>
      </div>
      <div class="col-md-4">
        <button class="btn btn-danger w-100" @click="interesse('semInteresse')">
          Não tem interesse
        </button>
      </div>
    </div>
    <!-- INTERESSE -->
    <div class="row" v-if="interesseVal == 'interesse'">
      <h2>Parabens</h2>
      <div class="col-md-12 mt-3">
        <button class="btn btn-success w-100" @click="salvarDevolucao()">
          Salvar
        </button>
      </div>
    </div>
    <div
      class="row"
      v-else-if="
        interesseVal == 'interesseNegociacao' || interesseVal == 'semInteresse'
      "
    >
      <div class="col-md-12">
        <label for="motivo">Motivo</label>
        <select
          class="form-select"
          id="motivo"
          @change="selecionaMotivo()"
          v-model="motivo"
        >
          <option value="">Selecione</option>
          <option
            v-for="option in motivoOption"
            :key="option.valor"
            :value="option.valor"
          >
            {{ option.descricao }}
          </option>
        </select>
      </div>
      <div class="col-md-12" v-if="mostraObs">
        <label for="motivo">Obs</label>
        <input type="text" class="form-control" id="obs" v-model="obs" />
      </div>
      <div class="col-md-12 mt-3">
        <button class="btn btn-success w-100" @click="salvarDevolucao()">
          Salvar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { request } from "../../assets/utils";

export default {
  props: {
    id_reserva: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      intencaoEscolhida: false,
      interesseVal: null,
      motivo: "",
      motivoOption: [],
      mostraObs: false,
      obs: "",
    };
  },
  methods: {
    async interesse(val) {
      const result = await Swal.fire({
        title: "Tem certeza?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        this.intencaoEscolhida = true;
        this.interesseVal = val;
      }
    },
    selecionaMotivo() {
      if (this.motivo == "Outros") this.mostraObs = true;
      else {
        this.mostraObs = false;
        this.obs = "";
      }
    },
    async salvarDevolucao() {
      const result = await Swal.fire({
        title: "Tem certeza?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        const load = this.$loading.show();
        const response = await request(
          this,
          "POST",
          "/chave/devolucao",
          this.$session.get("token"),
          {
            reserva_chave_id: this.id_reserva,
            interesse: this.interesseVal,
            motivo: this.motivo,
            outros: this.obs,
          }
        );
        load.hide();
        if (!response.error) {
          await Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Devolução Efetuada",
          });
          this.$router.go();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.message,
          });
        }
      }
    },
  },
  watch: {
    async interesseVal() {
      if (this.interesseVal != "interesse") {
        const load = this.$loading.show();
        const response = await request(
          this,
          "POST",
          "/objeto",
          this.$session.get("token"),
          {
            obj: [this.interesseVal],
          }
        );
        load.hide();
        if (!response.error) {
          this.motivoOption = response;
        }
      }
    },
  },
};
</script>

<style>
</style>