<template>
  <div class="container">
    <h2>Nova Reserva</h2>
    <hr />
    <ImovelForm v-on:salvar="salvaImovel"></ImovelForm>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import ImovelForm from "../components/imovel/ImovelForm.vue";
import { request } from "../assets/utils";

export default {
  components: { ImovelForm },
  methods: {
    async salvaImovel(info) {
      info.imovel_id = this.$route.params.id;
      const load = this.$loading.show();
      const response = await request(
        this,
        "POST",
        "/chave/novo",
        this.$session.get("token"),
        info
      );

      load.hide();
      if (!response.error) {
        this.$router.push({ path: "/" }, () => {
          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Chave reservada",
          });
        });
      }
    },
  },
};
</script>

<style>
</style>