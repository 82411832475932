import axios from 'axios';

export async function request(context, method, path, token, data) {
  try {
    const response = await axios({
      url: process.env.VUE_APP_BASE_URL + path,
      method: method,
      responseType: 'json',
      data: data,
      headers: {
        'Authorization': token
      }
    });

    return response.data;
  } catch (error) {

    if (!error.response) {
      return {
        error: true,
        message: 'Falha ao conectar a API'
      }
    }
    else if (error.response.status === 401) {

      localStorage.removeItem("token");
      context.$session.remove("token");
      context.$store.state.logado = false;

      return error.response.data;
    }
    else
      return error.response.data;
  }
}