<template>
  <tr>
    <td>
      <template v-if="!reserva.devolucao">
        <button class="btn btn-warning" @click="devolucao()">Devolução</button>
      </template>
    </td>
    <td>{{ reserva.cpf }}</td>
    <td>{{ reserva.nome }}</td>
    <td>{{ reserva.email }}</td>
    <td>{{ reserva.post_title }}</td>
    <td>{{ reserva.endereco }}</td>
    <td>{{ reserva.devolucao ? "Devolvido" : "Em empréstimo" }}</td>
  </tr>
</template>

<script>
export default {
  props: {
    reserva: {
      type: Object,
      required: true,
    },
  },
  methods:{
    devolucao(){
      this.$emit('devolucao',this.reserva);
    }
  }
};
</script>

<style>
</style>