<template>
  <div>
    <div class="row mb-4">
      <div class="col-md-12">
        <input type="text" class="form-control" id="filtro" v-model="filtro" />
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Imagem</th>
              <th scope="col">Titulo</th>
              <th scope="col">Chave</th>
              <th scope="col">Valor</th>
              <th scope="col">Endereço</th>
            </tr>
          </thead>
          <tbody>
            <ImovelItem
              v-for="imovel in imoveisFiltro"
              :key="imovel.id"
              :imovel="imovel"
            >
              <template v-if="imovel.chave == 'Disponivel'">
                <button @click="reservar(imovel.id)" class="btn btn-success">
                  Reservar
                </button>
              </template>
            </ImovelItem>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { request } from "../assets/utils";
import ImovelItem from "../components/imovel/ImovelItem";

export default {
  components: {
    ImovelItem,
  },
  data() {
    return {
      imoveis: [],
      imoveisFiltro: [],
      filtro: "",
    };
  },
  methods: {
    filtragem(value) {
      var re = new RegExp(this.filtro, "i");
      const endereco = value.endereco === null ? "" : value.endereco;
      return value.post_title.search(re) !== -1 || endereco.search(re) !== -1;
    },
    reservar(id) {
      this.$router.push({ path: `/reserva/${id}` });
    },
  },
  watch: {
    filtro() {
      this.imoveisFiltro = this.imoveis.filter(this.filtragem);
    },
  },
  async mounted() {
    const load = this.$loading.show();
    const response = await request(
      this,
      "GET",
      "/imoveis/lista",
      this.$session.get("token"),
      {}
    );
    if (!response.error) {
      this.imoveis = response;
      this.imoveisFiltro = response;
      load.hide();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response.message,
      });
    }
  },
};
</script>

<style>
</style>