<template>
  <div id="login" class="text-center">
    <main class="form-signin">
      <h1 class="h3 mb-3 fw-normal">Login</h1>
      <input
        type="email"
        id="email"
        class="form-control"
        placeholder="Email"
        v-model="email"
        autofocus
      />
      <input
        type="password"
        id="password"
        class="form-control"
        placeholder="Senha"
        v-model="senha"
      />
      <div class="checkbox mb-3">
        <label>
          <input type="checkbox" v-model="lembrar" /> Lembrar de mim
        </label>
      </div>
      <button class="w-100 btn btn-lg btn-primary" @click="logar" type="submit">
        Entrar
      </button>
    </main>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { request } from "../assets/utils";

export default {
  data() {
    return {
      email: "",
      senha: "",
      lembrar: true,
    };
  },
  methods: {
    async logar() {
      if (this.email && this.senha) {
        const load = this.$loading.show();

        const response = await request(this, "POST", "/login", null, {
          email: this.email,
          password: this.senha,
        });

        load.hide();
        if (!response.error) {
          if (this.lembrar) localStorage.setItem("token", response.token);

          this.$session.set("token", response.token);
          this.$store.state.logado = true;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.message,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Email e senha obrigatórios!",
        });
      }
    },
  },
};
</script>


<style>
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

#login {
  height: 100vh;
}

#login {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
