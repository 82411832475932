<template>
  <tr>
    <td>
      <slot></slot>
    </td>
    <td><img :src="imovel.thumb" class="card-img-top" alt="Imagem do imóvel" /></td>
    <td>{{ imovel.post_title }}</td>
    <td>{{ imovel.chave }}</td>
    <td>{{ imovel.value | moeda }}</td>
    <td>{{ imovel.endereco }}</td>
  </tr>
</template>

<script>
export default {
  props: {
    imovel: {
      type: Object,
      required: true,
    },
  },
  filters: {
    moeda: (value) => {
      var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });

      return formatter.format(value);
    },
  },
};
</script>

<style>
</style>