<template>
  <div>
    <VueModal
      v-model="showModal"
      :modalStyle="{ 'max-width': '80%' }"
      title="Relatório"
    >
      <RelatorioImovelUnico :imovel_id="id_imovel_relatorio"></RelatorioImovelUnico>
    </VueModal>
    <VueModal
      v-model="showModalLista"
      :modalStyle="{ 'max-width': '80%' }"
      title="Interesses"
    >
      <ListaInteresse :imovel_id="id_imovel_relatorio"></ListaInteresse>
    </VueModal>
    <div class="row mb-4">
      <div class="col-md-12">
        <input type="text" class="form-control" id="filtro" v-model="filtro" />
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Imagem</th>
              <th scope="col">Titulo</th>
              <th scope="col">Chave</th>
              <th scope="col">Valor</th>
              <th scope="col">Endereço</th>
            </tr>
          </thead>
          <tbody>
            <ImovelItem
              v-for="imovel in imoveisFiltro"
              :key="imovel.id"
              :imovel="imovel"
            >
              <button @click="relatorio(imovel.id)" class="btn btn-primary mb-2">
                Relatório
              </button>
              <button @click="interesse(imovel.id)" class="btn btn-success">
                Lista Interesse
              </button>
            </ImovelItem>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { request } from "../assets/utils";
import ImovelItem from "../components/imovel/ImovelItem";
import VueModal from "@kouts/vue-modal";
import RelatorioImovelUnico from '../components/relatorios/RelatorioImovelUnico.vue';
import ListaInteresse from '../components/relatorios/ListaInteresse.vue';

export default {
  components: {
    ImovelItem,
    VueModal,
    RelatorioImovelUnico,
    ListaInteresse
  },
  data() {
    return {
      imoveis: [],
      imoveisFiltro: [],
      filtro: "",
      showModal: false,
      showModalLista: false,
      id_imovel_relatorio : null
    };
  },
  methods: {
    filtragem(value) {
      var re = new RegExp(this.filtro, "i");
      const endereco = value.endereco === null ? "" : value.endereco;
      return value.post_title.search(re) !== -1 || endereco.search(re) !== -1;
    },
    relatorio(id) {
      this.id_imovel_relatorio = id;
      this.showModal = true;
    },
    interesse(id){
      this.id_imovel_relatorio = id;
      this.showModalLista = true;
    }
  },
  watch: {
    filtro() {
      this.imoveisFiltro = this.imoveis.filter(this.filtragem);
    },
  },
  async mounted() {
    const load = this.$loading.show();
    const response = await request(
      this,
      "GET",
      "/imoveis/lista",
      this.$session.get("token"),
      {}
    );
    if (!response.error) {
      this.imoveis = response;
      this.imoveisFiltro = response;
      load.hide();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response.message,
      });
    }
  },
};
</script>

<style>
</style>